import React from "react"
import { graphql , useStaticQuery} from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
import { FluidImage } from '@atoms/Image'
import Seo from "@atoms/Seo"
import Hero from "@blocks/page/Hero"
import { BreadcrumbList } from "@projects/BreadcrumbList"
import { Section, Title, SmallTitle, Paragraph, Column } from "@blocks/page/Section"
import { ContentFooter } from "@projects/ContentFooter"
import css from "@css/projects/page/p-content.module.styl"

export default function PrivacyPolicy() {
	const q = useStaticQuery(graphql`
        query {
            main01 : file(relativePath: { eq: "pageVision__img__hero@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
			}
			message : file(relativePath: { eq: "pageVision__img__message@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
			}
        }
    `)
	return (
		<>
			<Seo subtitle={'PRIVACY POLICY'} />
			<Hero
				image={q.main01}
				label='個人情報保護方針'
				title1='Privacy Policy'
				title2=''
				links={[
					// {
					// 	label: '代表メッセージ',
					// 	to: '#message'
					// }
				]}
			/>
			<div className={css.container}>
				<div className={css.inner}>
					<BreadcrumbList/>
					<Section>
						<Title title1='Privacy Policy' title2='個人情報保護方針' />
						<Paragraph>
							<p>
								大泉建設株式会社（以下「当社」といいます。）はお客様からの信頼を第一と考え、お客様個人に関わる情報を正確、かつ機密に取り扱うことは、当社にとって重要な責務であると考えております。その為に、お客様に関する個人情報に関する「個人情報保護方針」を制定し、個人情報の取り扱い方法について、全社員及び関連会社への徹底を実施してまいります。その内容は以下の通りです。なお、既に当社で保有している個人情報につきましても、本方針に従ってお客様の個人情報の取り扱いを実施致します。
							</p>
						</Paragraph>
					</Section>
					<Section>
						<SmallTitle title1='個人情報の取り扱いについて' title2='' />
						<Paragraph>
							<p>
								(1)個人情報の取得<br/>
								当社は個人情報を適正かつ公正な手段により収集致します。お客様に個人情報の提供をお願いする場合は、事前に収集の目的、利用の内容を開示した上で、当社の正当な事業の範囲内で、その目的の達成に必要な限度において、個人情報を収集します。
							</p>
							<p>
								(2)個人情報の利用及び共同利用<br/>
								当社がお預かりした個人情報は、個人情報を頂いた方に承諾を得た範囲内で、また収集目的に沿った範囲内で利用致します。利用目的については、以下の「利用目的の範囲」の内、当社の正当な事業の範囲内でその目的の達成に必要な事項を利用目的とします。
								<br /><br />
								●利用目的の範囲について<br />
								<ul>
									<li>業務上のご連絡をする場合</li>
									<li>当社が取り扱う商品及びサービスに関する御案内をする場合</li>
									<li>お客様からのお問い合わせまたは御依頼等への対応をさせて頂く場合</li>
									<li>その他、お客様に事前にお知らせし、ご同意頂いた目的の場合</li>
								</ul>
								<br />
								●上記目的以外の利用について <br />
								上記以外の目的で、お客様の個人情報を利用する必要が生じた場合には、法令により許される場合を除き、その利用について、お客様の同意を頂くものとします。 
							</p>
							<p>
								（3）個人情報の第三者提供 <br/>
								当社は、お客様の同意なしに第三者へお客様の個人情報の提供は行いません。但し個人情報に適用される法律その他の規範により、当社が従うべき法令上の義務等の特別な事情がある場合は、この限りではありません。
							</p>
							<p>
								（4）個人情報の開示・修正等の手続 <br/>
								お客様からご提供頂いた個人情報に関して、照会、訂正、削除を要望される場合は、お問い合わせ先窓口までご請求ください。当該ご請求が当社の業務に著しい支障をきたす場合等を除き、お客様ご本人によるものであることが確認できた場合に限り、合理的な期間内に、お客様の個人情報を開示、訂正、削除致します。
							</p>
							<p>
								（5）個人情報の開示等に要する手数料 <br/>
								開示請求者（お客様ご本人と認められる方）に対し開示等に要する手数料のご負担をお願いする場合がありますが、その場合はあらかじめその旨を明らかにしご負担頂くことと致します。
							</p>
						</Paragraph>
					</Section>
					<Section>
						<SmallTitle title1='個人情報の保護に関する法令・規範の遵守について' title2='' />
						<Paragraph>
							<p>
								当社は、当社が保有する個人情報に関して適用される個人情報保護関連法令及び規範を遵守します。また本方針は、日本国の法律、その他規範により判断致します。本方針は当社の個人情報の取り扱いに関しての基本的な方針を定めるものであり当社は本方針に則って個人情報保護法等の法令・規範に基づく個人情報の保護に努めます。
							</p>
						</Paragraph>
					</Section>
					<Section>
						<SmallTitle title1='個人情報の安全管理措置について' title2='' />
						<Paragraph>
							<p>
								当社は、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん、漏えい等から保護し、正確性及び安全性を確保するために管理体制を整備し、適切な安全対策を実施致します。個人情報を取り扱う事務所内への部外者の立ち入りを制限し、当社の個人情報保護に関わる役員・職員等全員に対し教育啓発活動を実施するほか管理責任者を置き個人情報の適切な管理に努めます。
							</p>
						</Paragraph>
					</Section>
					<Section>
						<SmallTitle title1='継続的な改善について' title2='' />
						<Paragraph>
							<p>
								当社は、個人情報保護への取組みについて、日本国の従うべき法令の変更、取り扱い方法、環境の変化に対応するため、継続的に見直し改善を実施致します。
							</p>
						</Paragraph>
					</Section>
					<Section>
						<SmallTitle title1='お問い合わせ' title2='' />
						<Paragraph>
							<p>
								個人情報の取り扱いに関するお問い合わせは、下記窓口にて受け付けております。<br/>
							</p>
							<p>
								【個人情報取扱い窓口】 <br/>
								大泉建設株式会社／TEL：0175-74-2468　FAX：0175-74-2368
							</p>
						</Paragraph>
					</Section>
				</div>
			</div>
			<ContentFooter/>
		</>
	)
}